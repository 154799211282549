import { Navigate, Route, Routes } from 'react-router-dom';
import { useBlueprintAdmin } from '../components/AdminProvider';
import {
  AdminActivity,
  AdminDetail,
  AdminEdit,
  AdminList,
  AdminNew,
  AdminServiceDetail,
  AdminServiceEdit,
  AdminServiceNew,
} from './admins';
import {
  AdminGroupDetail,
  AdminGroupEdit,
  AdminGroupNew,
} from './admins/groups';
import { AssetCreate, AssetDetail, AssetList } from './assets';
import { BeaconDetail, BeaconList, BeaconNew } from './beacons';
import DestinationsNew from './beacons/destinations/new';

import Login from './auth/login';
import { ConfigAll, ConfigList, ConfigNew } from './config';
import ConfigNotificationTemplateDetail from './config/notification-template/detail';
import ConfigNotificationTemplateList from './config/notification-template/list';
import ConfigNotificationTemplateNew from './config/notification-template/new';
import ConfigSidebar from './config/sidebar/manage';
import {
  ContentCollectionDelete,
  ContentCollectionDetail,
  ContentCollectionEdit,
  ContentCollectionList,
  ContentCollectionNew,
  ContentRecordDelete,
  ContentRecordDetail,
  ContentRecordEdit,
  ContentRecordNew,
} from './content';
import { UserIdentityDetail, UserIdentityList } from './identity';
import { JobDetail, JobEdit, JobList } from './jobs';
import JobNew from './jobs/new';
import { NotificationList, NotificationNew } from './notifications';
import Page404 from './page404';
import Page500 from './page500';
import {
  ProspectDetail,
  ProspectList,
  ProspectMatch,
  ProspectNew,
} from './prospects';

import { SegmentDetail, SegmentList, SegmentNew } from './segments';
import Setup from './setup';
import { TriggerList } from './triggers';
import TriggerDetail from './triggers/detail';
import NewTrigger from './triggers/new';
import { UserDetail, UserInviteList, UserInviteNew, UserList } from './users';
import UserExport from './users/export';
import {
  UserGroupAdd,
  UserGroupDetail,
  UserGroupEdit,
  UserGroupList,
  UserGroupNew,
} from './users/groups';
import Welcome from './welcome';
import { QueryClient, QueryClientProvider } from 'react-query';
import {
  ReportAcquisition,
  ReportEngagement,
  ReportList,
  ReportDetail,
  ReportOverview,
  ReportRetention,
} from './reports';
import AssetDelete from './assets/delete';

const BaseAdmin = ({ children = <></> }) => {
  const { config, mounted, isAuthenticated, isFirstAdmin } =
    useBlueprintAdmin();
  const queryClient = new QueryClient();

  return (
    <>
      {mounted && (
        <QueryClientProvider client={queryClient}>
          <Routes>
            <Route path='/auth/login' Component={Login} />
            <Route path='/setup' Component={Setup} />
            {isFirstAdmin && <Route path='/welcome' Component={Welcome} />}

            {/* Enable passed routes for a custom installation */}
            {children}

            {/* content */}
            <Route
              path='/content/collections/:collectionId/records/:recordId/edit'
              Component={ContentRecordEdit}
            />
            <Route
              path='/content/collections/:collectionId/records/:recordId/delete'
              Component={ContentRecordDelete}
            />
            <Route
              path='/content/collections/:collectionId/records/new'
              Component={ContentRecordNew}
            />
            <Route
              path='/content/collections/:collectionId/records/:recordId'
              Component={ContentRecordDetail}
            />
            <Route
              path='/content/collections/:collectionId/edit'
              Component={ContentCollectionEdit}
            />
            <Route
              path='/content/collections/:collectionId/delete'
              Component={ContentCollectionDelete}
            />
            <Route
              path='/content/collections/new'
              Component={ContentCollectionNew}
            />
            <Route
              path='/content/collections/:collectionId'
              Component={ContentCollectionDetail}
            />
            <Route path='/content' Component={ContentCollectionList} />

            {/* admin group */}
            <Route path='/admins/group/new' Component={AdminGroupNew} />
            <Route path='/admins/group/:id/edit' Component={AdminGroupEdit} />
            <Route path='/admins/group/:id' Component={AdminGroupDetail} />

            {/* admin */}
            <Route path='/admins/:id/edit' Component={AdminEdit} />
            <Route
              path='/admins/service/:id/edit'
              Component={AdminServiceEdit}
            />
            <Route path='/admins/activity' Component={AdminActivity} />
            <Route path='/admins/service/new' Component={AdminServiceNew} />
            <Route path='/admins/service/:id' Component={AdminServiceDetail} />
            <Route path='/admins/new' Component={AdminNew} />
            <Route path='/admins/:id' Component={AdminDetail} />
            <Route path='/admins' Component={AdminList} />

            {/* user group */}
            <Route path='/users/group/new' Component={UserGroupNew} />
            <Route path='/users/group/:id/add' Component={UserGroupAdd} />
            <Route path='/users/group/:id/edit' Component={UserGroupEdit} />
            <Route path='/users/group/:id' Component={UserGroupDetail} />
            <Route path='/users/group' Component={UserGroupList} />

            {/* user */}
            <Route path='/identity/:id' Component={UserIdentityDetail} />
            <Route path='/identity' Component={UserIdentityList} />
            <Route path='/users/export' Component={UserExport} />
            <Route path='/users/invites/new' Component={UserInviteNew} />
            <Route path='/users/invites' Component={UserInviteList} />
            <Route path='/users/:id' Component={UserDetail} />
            <Route path='/users' Component={UserList} />

            {/* prospects */}
            <Route path='/prospects/new' Component={ProspectNew} />
            <Route path='/prospects/match/:id' Component={ProspectMatch} />
            <Route path='/prospects/:id' Component={ProspectDetail} />
            <Route path='/prospects' Component={ProspectList} />

            {/* triggers */}
            <Route path='/triggers/new' Component={NewTrigger} />
            <Route path='/triggers/:id' Component={TriggerDetail} />
            <Route path='/triggers' Component={TriggerList} />

            {/* assets */}
            <Route path='/assets/new' Component={AssetCreate} />
            <Route path='/assets/:id' Component={AssetDetail} />
            <Route path='/assets' Component={AssetList} />
            <Route path='/assets/:id/delete' Component={AssetDelete} />

            {/* jobs */}
            <Route path='/jobs/:id/edit' Component={JobEdit} />
            <Route path='/jobs/new' Component={JobNew} />
            <Route path='/jobs/:id' Component={JobDetail} />
            <Route path='/jobs' Component={JobList} />

            {/* notifications */}
            <Route path='/notifications/new' Component={NotificationNew} />
            <Route path='/notifications' Component={NotificationList} />

            {/* segments */}
            <Route path='/segments/new' Component={SegmentNew} />
            <Route path='/segments/:id' Component={SegmentDetail} />
            <Route path='/segments' Component={SegmentList} />

            {/* notification template configurations */}
            <Route
              path='/config/notification-template/new'
              Component={ConfigNotificationTemplateNew}
            />
            <Route
              path='/config/notification-template/:id'
              Component={ConfigNotificationTemplateDetail}
            />
            <Route
              path='/config/notification-template'
              Component={ConfigNotificationTemplateList}
            />

            {/* config configurations */}
            <Route path='/config/all' Component={ConfigAll} />

            {/* beacon configurations */}
            <Route path='/beacons/new' Component={BeaconNew} />
            <Route
              path='/beacons/:id/destinations/new'
              Component={DestinationsNew}
            />
            <Route path='/beacons/:id' Component={BeaconDetail} />
            <Route path='/beacons' Component={BeaconList} />

            {/* configurations */}
            <Route path='/config/sidebar' Component={ConfigSidebar} />
            <Route path='/config/new' Component={ConfigNew} />
            <Route path='/config' Component={ConfigList} />

            <Route path='/reports/detail' Component={ReportDetail} />
            <Route path='/reports/acquisition' Component={ReportAcquisition} />
            <Route path='/reports/retention' Component={ReportRetention} />
            <Route path='/reports/engagement' Component={ReportEngagement} />
            <Route path='/reports/overview' Component={ReportOverview} />
            <Route path='/reports' Component={ReportList} />
          </Routes>
          {isFirstAdmin && <Navigate to='/welcome' />}
          {!isAuthenticated &&
            !isFirstAdmin &&
            location.pathname !== '/auth/callback' && (
              <Navigate to='/auth/login' />
            )}
        </QueryClientProvider>
      )}
    </>
  );
};

export default BaseAdmin;
