import { CheckCircle } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Link as UiLink,
} from '@mui/material';
import { useState } from 'react';
import { Link } from 'react-router-dom';

interface IAdminCard {
  title?: any;
  children?: any;
  editActionText?: string;
  editAction?: string;
  newAction?: string;
  viewAction?: string;
  deleteAction?: string;
  exportExcelAction?: any;
  customAction?: any;
  selectable?: boolean;
  onSelect?: any;
  id?: string;
  showCard?: boolean;
}

const AdminCard = ({
  title = undefined,
  children,
  editActionText = 'Edit',
  editAction = '',
  newAction = '',
  viewAction = '',
  deleteAction = '',
  exportExcelAction,
  customAction,
  selectable = false,
  onSelect,
  showCard = true,
  id,
}: IAdminCard) => {
  const [selected, setSelected] = useState(true);
  const [isRaised, setIsRaised] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState('white');
  const [foregroundColor, setForegroundColor] = useState('black');

  const setAction = (
    viewAction,
    newAction,
    editAction,
    deleteAction,
    exportExcelAction,
    customAction
  ) => (
    <>
      {viewAction && (
        <UiLink to={viewAction} component={Link}>
          View
        </UiLink>
      )}
      {newAction && (
        <Button
          variant='outlined'
          color='primary'
          to={newAction}
          component={Link}
          style={{ textDecoration: 'none' }}
        >
          New +
        </Button>
      )}
      {editAction && (
        <Button
          variant='outlined'
          color='primary'
          to={editAction}
          component={Link}
        >
          {editActionText}
        </Button>
      )}
      {deleteAction && (
        <Link to={`${deleteAction}`} style={{ marginLeft: '16px' }}>
          <Button style={{ backgroundColor: '#d32f2f', color: '#fff' }}>
            Delete
          </Button>
        </Link>
      )}
      {exportExcelAction && <>{exportExcelAction}</>}
      {customAction && <>{customAction}</>}
    </>
  );

  const _onSelected = () => {
    if (selectable) {
      setSelected(!selected);

      if (selected) {
        setBackgroundColor('#43a047');
        setForegroundColor('white');
      } else {
        setBackgroundColor('white');
        setForegroundColor('black');
      }

      if (onSelect) {
        onSelect(id, selected);
      }
    }
  };

  const getTitle = () => (
    <>
      {title}
      {selectable && !selected && <CheckCircle style={{ marginLeft: 20 }} />}
    </>
  );

  return (
    <>
      {showCard && (
        <Card
          sx={{
            padding: 1,
            minHeight: 100,
            width: '100%',
            marginBottom: 3,
          }}
          style={{
            backgroundColor: backgroundColor,
            color: foregroundColor,
          }}
          onMouseOver={() => setIsRaised(true)}
          onMouseOut={() => setIsRaised(false)}
          raised={isRaised}
          onClick={() => _onSelected()}
        >
          {title && (
            <CardHeader
              titleTypographyProps={{ fontWeight: 'bolder', margin: 0 }}
              title={getTitle()}
              action={setAction(
                viewAction,
                newAction,
                editAction,
                deleteAction,
                exportExcelAction,
                customAction
              )}
            ></CardHeader>
          )}
          <CardContent>{children}</CardContent>
        </Card>
      )}
      {!showCard && <>{children}</>}
    </>
  );
};

export default AdminCard;
