export const variableRoles = [
  'asset',
  'content',
  'notification',
  'schedules',
  'trigger',
  'user',
  'group',
];

export const roles = [
  {
    type: 'string',
    enum: ['admin:admin:base'],
    title: 'Admins | Base Access (admin:admin:base)',
  },
  {
    type: 'string',
    enum: ['admin:admin:*'],
    title: 'Admins | Full Access (admin:admin:*)',
  },
  {
    type: 'string',
    enum: ['admin:config:*'],
    title: 'Admin config | Full Access (admin:config:*)',
  },
  {
    type: 'string',
    enum: ['admin:adminGroup:*'],
    title: 'Admin Groups | Full Access (admin:adminGroup:*)',
  },
  {
    type: 'string',
    enum: ['admin:beacon:*'],
    title: 'Beacons | Full Access (admin:beacon:*)',
  },
  {
    type: 'string',
    enum: ['admin:invite:*'],
    title: 'User Invites | Full Access (admin:invite:*)',
  },
  {
    type: 'string',
    enum: ['admin:user:*'],
    title: 'Users | Full Access (admin:user:*)',
  },
  {
    type: 'string',
    enum: ['admin:userGroup:*'],
    title: 'User Groups | Full Access (admin:userGroup:*)',
  },
  {
    type: 'string',
    enum: ['admin:prospect:*'],
    title: 'User Prospects | Full Access (admin:prospect:*)',
  },
  {
    type: 'string',
    enum: ['admin:activity:*'],
    title: 'Activity: Activity | Full Access (admin:activity:*)',
  },
  {
    type: 'string',
    enum: ['admin:asset:*'],
    title: 'Assets | Full Access (admin:asset:*)',
  },
  {
    type: 'string',
    enum: ['admin:trigger:*'],
    title: 'Triggers | Full Access (admin:trigger:*)',
  },
  {
    type: 'string',
    enum: ['admin:collection:*'],
    title: 'Content Collections | Full Access (admin:collection:*)',
  },
  {
    type: 'string',
    enum: ['admin:collection:create'],
    title: 'Content Collections | Create (admin:collection:create)',
  },
  {
    type: 'string',
    enum: ['admin:collection:read'],
    title: 'Content Collections | Read (admin:collection:read)',
  },
  {
    type: 'string',
    enum: ['admin:collection:delete'],
    title: 'Content Collections | Delete (admin:collection:delete)',
  },
  {
    type: 'string',
    enum: ['admin:collection:publish'],
    title: 'Content Collections | Publish (admin:collection:publish)',
  },
  {
    type: 'string',
    enum: ['admin:stream:*'],
    title: 'Content Streams | Full Access (admin:stream:*)',
  },
  {
    type: 'string',
    enum: ['admin:alerts:*'],
    title: 'Alerts | Full Access (admin:alerts:*)',
  },
  {
    type: 'string',
    enum: ['admin:comment:*'],
    title: 'Comments | Full Access (admin:comment:*)',
  },
  {
    type: 'string',
    enum: ['admin:reaction:*'],
    title: 'Reactions | Full Access (admin:reaction:*)',
  },
  {
    type: 'string',
    enum: ['admin:notification:*'],
    title: 'Notifications | Full Access (admin:notification:*)',
  },
  {
    type: 'string',
    enum: ['admin:cacheTemplate:*'],
    title: 'Cache Template | Full Access (admin:cacheTemplate:*)',
  },
  {
    type: 'string',
    enum: ['admin:segment:*'],
    title: 'Segments | Full Access (admin:segment:*)',
  },
  {
    type: 'string',
    enum: ['admin:channel:*'],
    title: 'Notification Channels | Full Access (admin:channel:*)',
  },
  {
    type: 'string',
    enum: ['admin:schedule:*'],
    title: 'Schedules | Full Access (admin:schedule:*)',
  },
  {
    type: 'string',
    enum: ['user:user:base'],
    title: 'Users | Base Access (user:user:base)',
  },
];
