import { KeyboardReturn, Loop, Visibility } from '@mui/icons-material';
import { Grid, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { Link, useNavigate } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';

const useStyles = makeStyles((theme: Theme) => ({
  card: {
    paddingBottom: theme.spacing(2),
  },
}));

const ReportList = () => {
  const navigate = useNavigate();

  return (
    <AdminPage
      animation={true}
      title='Reports'
      description='Reports take activity data and bring it to life'
    >
      <Grid container xs={12}>
        <Typography variant='h6'>General dashboard</Typography>
        <Grid item xs={12} style={{ padding: 20 }}>
          <AdminCard
            selectable={true}
            onSelect={() => {
              navigate('/reports/overview');
            }}
            title='Overview Dashboard'
          >
            <Typography>
              This dashboard provides high-level analytics at a beacon level, to
              allow you to understand and collect insights in real-time.
            </Typography>
          </AdminCard>
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Typography variant='h6'>
          When you need a lifecycle based report
        </Typography>
        <Grid container xs={12}>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminCard
              selectable={true}
              onSelect={() => {
                navigate('/reports/acquisition');
              }}
              title={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Visibility style={{ color: '#000' }} fontSize='large' />
                  <span>&nbsp;Acquisition</span>
                </div>
              }
            >
              <Typography>
                Driving acquisition from various channels to understanding what
                motivates and drives engagement
              </Typography>
            </AdminCard>
          </Grid>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminCard
              selectable={true}
              onSelect={() => {
                navigate('/reports/engagement');
              }}
              title={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <Loop style={{ color: '#000' }} fontSize='large' />
                  <span>&nbsp;Engagement</span>
                </div>
              }
            >
              <Typography>
                A user experience that informs and retains users through a fun,
                engaging and impactful experience
              </Typography>
            </AdminCard>
          </Grid>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminCard
              selectable={true}
              onSelect={() => {
                navigate('/reports/retention');
              }}
              title={
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexWrap: 'wrap',
                  }}
                >
                  <KeyboardReturn style={{ color: '#000' }} fontSize='large' />
                  <span>&nbsp;Retention</span>
                </div>
              }
            >
              <Typography>
                Retain users to drive overall usage and create a connected
                relationship
              </Typography>
            </AdminCard>
          </Grid>
        </Grid>
      </Grid>
    </AdminPage>
  );
};

export default ReportList;
