import { Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AdminCard from '../../components/AdminCard';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';

const AssetDelete = () => {
  const { adminAssetApi } = useBlueprintAdmin();
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const [asset, setAsset] = useState<any>();

  const handleDelete = async (e) => {
    // SEND TO DELETE
    e.stopPropagation();
    e.preventDefault();
    await adminAssetApi
      /** @TODO update the type for updateAsset body */
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      /** @ts-ignore */
      ?.updateAsset(id!, { status: 'inactive' })
      .then(() => {
        navigate(`/assets`);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const handleCancel = (e) => {
    e.stopPropagation();
    e.preventDefault();
    navigate(`/assets`);
  };

  useEffect(() => {
    if (id && adminAssetApi) {
      adminAssetApi
        .getAssetById(id)
        .then(({ data }) => {
          setAsset(data);
        })
        .catch((err) => console.error(err));
    }
  }, [id]);

  return (
    <>
      {id && asset && (
        <AdminPage title='Delete Asset'>
          <AdminCard title=''>
            <h4>
              Are you sure you want to delete <strong>{asset.name}</strong>?
            </h4>

            <Button onClick={(e) => handleDelete(e)} color='error'>
              Confirm Deletion
            </Button>
            <Button onClick={(e) => handleCancel(e)}>Cancel</Button>
          </AdminCard>
        </AdminPage>
      )}
    </>
  );
};
export default AssetDelete;
