import { CubeProvider } from '@cubejs-client/react';
import cubejs from '@cubejs-client/core';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminChart from '../../components/reporting/AdminChart';
import { Grid, Typography } from '@mui/material';
import AdminCard from '../../components/AdminCard';
import AdminNumberWithTrend from '../../components/reporting/AdminNumberWithTrend';

const ReportEngagement = () => {
  const { config, jwtToken } = useBlueprintAdmin();
  const cubejsApi = cubejs(jwtToken!, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <AdminPage title='Engagement'>
        <Grid container xs={12}>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminNumberWithTrend
              title='Activity Today'
              numberQuery={{
                measures: ['Activity.count'],
                timeDimensions: [
                  {
                    dimension: 'Activity.sentAt',
                    dateRange: 'Today',
                  },
                ],
                order: {},
                filters: [],
                dimensions: [],
              }}
              trendQuery={{
                measures: ['Activity.count'],
                timeDimensions: [
                  {
                    dimension: 'Activity.sentAt',
                    dateRange: 'Last 30 days',
                    granularity: 'day',
                  },
                ],
                order: {
                  'Activity.sentAt': 'asc',
                },
                filters: [],
                dimensions: [],
              }}
            />
          </Grid>

          <Grid item xs={8} style={{ padding: 20 }}>
            <AdminChart
              title='Most Recent Activity'
              showViewDetail={false}
              vizState={{
                chartType: 'table',
                legend: false,
                query: {
                  measures: [],
                  timeDimensions: [],
                  order: {
                    'Activity.timestamp': 'desc',
                  },
                  filters: [],
                  dimensions: [
                    'Activity.event',
                    'Activity.timestamp',
                    'Activity.id',
                  ],
                  limit: 25,
                },
              }}
            />
          </Grid>
        </Grid>
      </AdminPage>
    </CubeProvider>
  );
};

export default ReportEngagement;
