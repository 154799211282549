import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { Grid } from '@mui/material';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminChart from '../../components/reporting/AdminChart';
import AdminNumberWithTrend from '../../components/reporting/AdminNumberWithTrend';

const ReportGeneral = () => {
  const { config, adminActivityApi, jwtToken } = useBlueprintAdmin();
  const cubejsApi = cubejs(jwtToken!, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <AdminPage title='General Report'>
        <Grid container xs={12}>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminNumberWithTrend
              title='Monthly Active Users'
              numberQuery={{
                measures: ['Activity.monthlyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.timestamp',
                    dateRange: 'This month',
                  },
                ],
                order: {},
                filters: [],
                dimensions: [],
              }}
              trendQuery={{
                measures: ['Activity.monthlyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.sentAt',
                    granularity: 'month',
                    dateRange: 'Last 6 months',
                  },
                ],
                order: {
                  'Activity.sentAt': 'asc',
                },
                filters: [],
                dimensions: [],
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminNumberWithTrend
              title='Weekly Active Users'
              numberQuery={{
                measures: ['Activity.weeklyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.timestamp',
                    dateRange: 'This week',
                  },
                ],
                order: {},
                filters: [],
                dimensions: [],
              }}
              trendQuery={{
                measures: ['Activity.weeklyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.sentAt',
                    granularity: 'week',
                    dateRange: 'Last 3 months',
                  },
                ],
                order: {
                  'Activity.sentAt': 'asc',
                },
                filters: [],
                dimensions: [],
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminNumberWithTrend
              title='Daily Active Users'
              numberQuery={{
                measures: ['Activity.dailyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.timestamp',
                    dateRange: 'Today',
                  },
                ],
                order: {},
                filters: [],
                dimensions: [],
              }}
              trendQuery={{
                measures: ['Activity.dailyActiveUsers'],
                timeDimensions: [
                  {
                    dimension: 'Activity.timestamp',
                    dateRange: 'Last 30 days',
                    granularity: 'day',
                  },
                ],
                order: {
                  'Activity.sentAt': 'asc',
                },
                filters: [],
                dimensions: [],
              }}
            />
          </Grid>
          <Grid item xs={4} style={{ padding: 20 }}>
            <AdminChart
              title='Browser Breakdown'
              showViewDetail={false}
              vizState={{
                chartType: 'pie',
                legend: true,
                query: {
                  measures: ['Activity.count'],
                  timeDimensions: [
                    {
                      dimension: 'Activity.timestamp',
                    },
                  ],
                  order: {
                    'Activity.count': 'desc',
                  },
                  filters: [],
                  dimensions: ['Activity.deviceClientName'],
                },
              }}
            />
          </Grid>
        </Grid>
      </AdminPage>
    </CubeProvider>
  );
};

export default ReportGeneral;
