import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import searchClient from '../../../utils/search';
import AdminBaseForm from '../../../components/AdminBaseForm';
import { useBlueprintAdmin } from '../../../components/AdminProvider';
import getEnv from '../../../utils/getEnv';

const UserGroupAdd = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id: string }>();
  const { adminUserApi } = useBlueprintAdmin();
  const [users, setUsers] = useState<any>([]);

  useEffect(() => {
    if (getEnv('REACT_APP_SEARCH_API_DOMAIN')) {
      searchClient
        .index('admin-users')
        .search('', { limit: 9999 })
        .then((res) => {
          if (res.hits) {
            const meiliUser = res.hits.map((user) => ({
              type: 'string',
              enum: user,
              enumNames: user.username,
            }));
            setUsers(meiliUser);
          }
        })
        .catch((err) => {
          console.error(err);
          return err;
        });
    } else {
      adminUserApi
        ?.getUsers({ limit: 9999, page: 1 })
        .then(({ data }) => {
          const response = data.map((user) => ({
            type: 'string',
            enum: user,
            enumNames: user.username,
          }));
          setUsers(response);
        })
        .catch((err) => console.error(err));
    }
  }, []);

  const onSuccess = () => {
    navigate(`/users/group/${id}`);
  };

  const schema = {
    description: 'Add user to group',
    type: 'object',
    properties: {
      userId: {
        type: 'string',
        title: 'User',
        enum: users.map((user: any) => user.enum.id),
        enumNames: users.map((user: any) => user.enumNames),
        description: 'The user we are adding to the group',
      },
      groupId: {
        type: 'string',
        default: id,
      },
      relationship: {
        type: 'string',
        title: 'Relationship',
        description: 'The type of relationship associated with the user',
      },
    },
    required: ['userId'],
  };

  const uiSchema = {
    userId: { 'ui:widget': 'autoComplete' },
    groupId: { 'ui:widget': 'hidden' },
  };

  return (
    <AdminBaseForm
      url='/admin/user-groups/add'
      entityTitle='User Group'
      schema={schema}
      uiSchema={uiSchema}
      onSuccess={onSuccess}
    />
  );
};

export default UserGroupAdd;
