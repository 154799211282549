import cubejs from '@cubejs-client/core';
import { CubeProvider } from '@cubejs-client/react';
import { FormControl, FormLabel, Grid, MenuItem, Select } from '@mui/material';
import { useEffect, useState } from 'react';
import AdminPage from '../../components/AdminPage';
import { useBlueprintAdmin } from '../../components/AdminProvider';
import AdminChart from '../../components/reporting/AdminChart';
import AdminNumberWithTrend from '../../components/reporting/AdminNumberWithTrend';
import ScopedReportingTable from '../../components/reporting/ScopedReportingTable';

const ReportOverview = () => {
  const { config, adminActivityApi, jwtToken } = useBlueprintAdmin();

  const cubejsApi = cubejs(jwtToken!, {
    apiUrl: config.apiRoot + '/admin/reporting/v1',
  });
  const [beaconFilter, setBeaconFilter] = useState<any>(undefined);
  const [beaconOptions, setBeaconOptions] = useState<Array<any>>([]);
  const [selectedBeacon, setSelectedBeacon] = useState<any>(undefined);
  const [beaconDimensions] = useState<any>(['Activity.beaconKey']);
  const [granularityOptions, setGranularityOptions] = useState<any>(
    'from 7 days ago to now'
  );
  const [beacons, setBeacons] = useState<undefined | Array<any>>(undefined);

  useEffect(() => {
    if (adminActivityApi) {
      adminActivityApi
        .getBeacons({ limit: 999, page: 1 })
        .then(({ data }) => {
          setBeaconFilter([
            {
              member: 'Activity.beaconKey',
              operator: 'equals',
              values: [data[0]?.key],
            },
          ]);
          setBeacons([...data]);

          const beaconOptionList = [
            ...data.map((value) => ({
              id: value.id,
              name: value.name,
              value: value.key,
            })),
          ];

          setBeaconOptions(beaconOptionList);
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [adminActivityApi]);

  const GranularityRangeOptions = [
    {
      label: 'This Week',
      value: 'from 7 days ago to now',
    },
    {
      label: 'This Month',
      value: 'from 30 days ago to now',
    },
    {
      label: 'This Year',
      value: 'from 1 year ago to now',
    },
  ];

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      {beacons && beaconFilter && beaconDimensions && granularityOptions && (
        <AdminPage title='Overview'>
          <Grid container xs={12}>
            <Grid container xs={12} style={{ paddingBottom: 10 }}>
              <Grid item xs={6} spacing={3}></Grid>
              <Grid container item xs={6} spacing={3}>
                <Grid item xs={6} spacing={3}>
                  <FormControl margin='dense' fullWidth>
                    <FormLabel component='legend'>Beacon</FormLabel>
                    <Select
                      variant='outlined'
                      value={selectedBeacon}
                      onChange={(e) => {
                        const val = e.target.value;

                        if (val) {
                          // If All Beacons is set, then dont filter anything

                          setSelectedBeacon(val);
                          setBeaconFilter([
                            {
                              member: 'Activity.beaconKey',
                              operator: 'equals',
                              values: [val],
                            },
                          ]);

                          console.error({
                            member: 'Activity.beaconKey',
                            operator: 'equals',
                            values: [val],
                          });
                        }
                      }}
                    >
                      {beaconOptions.map((item, i) => (
                        <MenuItem key={`${item.id}`} value={item.value}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} spacing={3}>
                  <FormControl margin='dense' fullWidth>
                    <FormLabel component='legend'>Time Range</FormLabel>
                    <Select
                      variant='outlined'
                      value={granularityOptions}
                      onChange={(e) => {
                        const val = e.target.value;
                        console.error(val);
                        setGranularityOptions(val);
                      }}
                    >
                      {GranularityRangeOptions.map((item, i) => (
                        <MenuItem key={`${item.label}-${i}`} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>

            {selectedBeacon && (
              <>
                {/* Top Daily Active User Chart */}
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <AdminChart
                      title={`Daily Active Users`}
                      showViewDetail={false}
                      showCard={true}
                      vizState={{
                        chartType: 'line',
                        legend: false,
                        query: {
                          measures: ['Activity.dailyActiveUsers'],
                          timeDimensions: [
                            {
                              dimension: 'Activity.sentAt',
                              dateRange: granularityOptions,
                              granularity: 'day',
                            },
                          ],
                          order: {
                            'Activity.sentAt': 'asc',
                          },
                          filters: beaconFilter,
                          dimensions: [],
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                {/* MAU, WAU, DAU User List */}
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={4}>
                    <AdminNumberWithTrend
                      title='Monthly Active Users'
                      numberQuery={{
                        measures: ['Activity.monthlyActiveUsers'],
                        timeDimensions: [
                          {
                            dimension: 'Activity.timestamp',
                            dateRange: 'Last 30 days',
                          },
                        ],
                        order: {},
                        filters: beaconFilter,
                        dimensions: beaconDimensions,
                      }}
                      trendQuery={{
                        measures: ['Activity.monthlyActiveUsers'],
                        timeDimensions: [
                          {
                            dimension: 'Activity.timestamp',
                            granularity: 'month',
                            dateRange: 'Last 6 months',
                          },
                        ],
                        order: {
                          'Activity.sentAt': 'asc',
                        },
                        filters: beaconFilter,
                        dimensions: beaconDimensions,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AdminNumberWithTrend
                      title='Weekly Active Users'
                      numberQuery={{
                        measures: ['Activity.weeklyActiveUsers'],
                        timeDimensions: [
                          {
                            dimension: 'Activity.timestamp',
                            dateRange: 'This week',
                          },
                        ],
                        order: {},
                        filters: beaconFilter,
                        dimensions: beaconDimensions,
                      }}
                      trendQuery={{
                        measures: ['Activity.weeklyActiveUsers'],
                        timeDimensions: [
                          {
                            dimension: 'Activity.timestamp',
                            granularity: 'week',
                            dateRange: 'Last 3 months',
                          },
                        ],
                        order: {
                          'Activity.sentAt': 'asc',
                        },
                        filters: beaconFilter,
                        dimensions: beaconDimensions,
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <AdminNumberWithTrend
                      title='Daily Active Users'
                      numberQuery={{
                        measures: ['Activity.dailyActiveUsers'],
                        timeDimensions: [
                          {
                            dimension: 'Activity.timestamp',
                            dateRange: 'Last 1 day',
                          },
                        ],
                        order: {},
                        filters: beaconFilter,
                        dimensions: beaconDimensions,
                      }}
                      trendQuery={{
                        measures: ['Activity.dailyActiveUsers'],
                        timeDimensions: [
                          {
                            dimension: 'Activity.timestamp',
                            dateRange: 'Last 30 days',
                            granularity: 'day',
                          },
                        ],
                        order: {
                          'Activity.sentAt': 'asc',
                        },
                        filters: beaconFilter,
                        dimensions: beaconDimensions,
                      }}
                    />
                  </Grid>
                  {/* <Grid item xs={4}>
            <AdminChart
              title='Browser Breakdown'
              showViewDetail={false}
              vizState={{
                chartType: 'pie',
                legend: true,
                query: {
                  measures: ['Activity.count'],
                  timeDimensions: [
                    {
                      dimension: 'Activity.timestamp',
                    },
                  ],
                  order: {
                    'Activity.count': 'desc',
                  },
                  filters: beaconFilter,
                  dimensions: ['Activity.deviceClientName'],
                },
              }}
            />
          </Grid> */}
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid container item xs={4}>
                    <ScopedReportingTable
                      title='Device - Client Name'
                      limiter='Activity.deviceClientName'
                      beaconFilter={beaconFilter}
                      beaconDimensions={beaconDimensions}
                      granularity={granularityOptions}
                    />
                  </Grid>

                  <Grid container item xs={4}>
                    <ScopedReportingTable
                      title='Language'
                      limiter='Activity.language'
                      beaconFilter={beaconFilter}
                      beaconDimensions={beaconDimensions}
                      granularity={granularityOptions}
                    />
                  </Grid>

                  <Grid container item xs={4}>
                    <ScopedReportingTable
                      title='Screen Size'
                      limiter='Activity.screen'
                      beaconFilter={beaconFilter}
                      beaconDimensions={beaconDimensions}
                      granularity={granularityOptions}
                    />
                  </Grid>
                </Grid>

                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={12}>
                    <AdminChart
                      title={`Daily Events`}
                      showViewDetail={false}
                      showCard={true}
                      vizState={{
                        chartType: 'line',
                        legend: false,
                        query: {
                          measures: ['Activity.count'],
                          timeDimensions: [
                            {
                              dimension: 'Activity.sentAt',
                              dateRange: granularityOptions,
                              granularity: 'day',
                            },
                          ],
                          order: {
                            'Activity.sentAt': 'asc',
                          },
                          filters: beaconFilter,
                        },
                      }}
                    />
                  </Grid>
                </Grid>

                {/* Top Events, Pages, Top Referrers */}
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={6}>
                    <ScopedReportingTable
                      title='Top Events'
                      limiter='Activity.event'
                      beaconFilter={beaconFilter}
                      beaconDimensions={beaconDimensions}
                      granularity={granularityOptions}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <ScopedReportingTable
                      title='Top Referrers'
                      limiter='Activity.referrer'
                      beaconFilter={beaconFilter}
                      beaconDimensions={beaconDimensions}
                      granularity={granularityOptions}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <ScopedReportingTable
                      title='Top Pages'
                      limiter='Activity.url'
                      beaconFilter={beaconFilter}
                      beaconDimensions={beaconDimensions}
                      granularity={granularityOptions}
                    />
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </AdminPage>
      )}
    </CubeProvider>
  );
};

export default ReportOverview;
